import type { AppProps } from "next/app";
import { Global } from "@emotion/react";
import globalStyles from "@/styles/global";
import ThemeMui from "@/styles/ThemeMui";
import Layout from "@/layout/Layout";
import { Provider } from "react-redux";
import Store from "@/store/store/Store";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import dotenv from "dotenv";
import { AuthProvider } from "@/store/auth/AuthContext";
import Head from "next/head";
import LinksLayout from "@/layout/LinksLayout";
import LayoutAdmin from "@/layout/LayoutAdmin";
import RouterLoading from "@/layout/RouterLoading";
import LayoutMember from "@/layout/LayoutMember";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { fetchMenuStatic } from "@/store/reducers/CoreSlice";
import { MenuType } from "@/store/types/coreTypes";
import { PostType } from "@/store/types/blogTypes";
import { fetchLastPostsStatic } from "@/store/reducers/BlogSlice";
import Script from "next/script";

dotenv.config();

const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? "";

const layouts: any = {
  Links: LinksLayout,
  Admin: LayoutAdmin,
  Member: LayoutMember,
};

function App(props: AppProps) {
  const { Component, pageProps, menu, posts }: any = props;

  const ActLayout = layouts[Component.layout] || Layout;

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-5N5Q4GG3",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
        <meta
          name="google-adsense-account"
          content="ca-pub-4225378639341312"
        ></meta>
      </Head>
      <Script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4225378639341312"
        crossOrigin="anonymous"
      ></Script>
      <Provider store={Store}>
        <Global styles={globalStyles} />

        <ThemeMui>
          <AuthProvider>
            <ActLayout menu={menu} posts={posts}>
              <RouterLoading>
                <Component {...pageProps} />
              </RouterLoading>
            </ActLayout>
          </AuthProvider>
        </ThemeMui>
      </Provider>
    </>
  );
}

App.getInitialProps = async (): Promise<any> => {
  let menu: MenuType[] | undefined = undefined;
  let posts: PostType[] | undefined = undefined;

  try {
    menu = await fetchMenuStatic();
    posts = await fetchLastPostsStatic();
  } catch (error) {
    console.error("Erro ao buscar o menu:", error);
  }

  return {
    menu,
    posts,
  };
};

export default App;
